<template>
  <SmoothModal
    v-model="value"
    width="440px"
    :has-header="false"
    @update:model-value="closeModal"
  >
    <div class="wallet">
      <AppIcon name="wallet-red" size="40px" is-img-tag />

      <AppText
        variant="div"
        size="17px"
        class="font-medium"
        mt="20px"
      >
        {{ t('component.disconnectWallet.title') }}
      </AppText>

      <AppText
        variant="div"
        :line-height="1.35"
        class="font-medium"
        mt="30px"
      >
        {{ t('component.disconnectWallet.label.understand') }}:
      </AppText>

      <ActionForm
        :request-fields="requestFields"
        :validation-schema="validationSchema"
        :response-errors="fieldsErrors"
        @validate="onPassedValidation"
      >
        <template #default="{ errors, action }">
          <div class="rules">
            <FCheckbox
              v-model="requestFields.rule1"
              is-wrap
              :has-error="Boolean(errors.rule1?.message)"
              :label="t('component.disconnectWallet.option1')"
            />
            <FCheckbox
              v-model="requestFields.rule2"
              is-wrap
              :has-error="Boolean(errors.rule2?.message)"
              :label="t('component.disconnectWallet.option2')"
            />
            <FCheckbox
              v-model="requestFields.rule3"
              is-wrap
              :has-error="Boolean(errors.rule2?.message)"
              :label="t('component.disconnectWallet.option3')"
            />
          </div>

          <AppText variant="div" mt="20px" mb="10px">
            {{ t('component.disconnectWallet.label.totp') }}
          </AppText>

          <SplitInput
            v-model="requestFields.totpCode"
            type="dashed"
            :state="errors.totpCode ? 'error' : ''"
            is-auto-clear-error
            :error-message="errors.totpCode?.message"
          />

          <div class="d-flex justify-content-between" style="gap: 10px">
            <FButton
              is-full-width
              type="danger"
              :loading="isRemoveProcessLoading"
              @click="action"
            >
              {{ t('component.disconnectWallet.button.disconnect') }}
            </FButton>
            <FButton is-full-width type="plain" @click="closeModal">
              {{ t('common.cancel') }}
            </FButton>
          </div>
        </template>
      </ActionForm>
    </div>
  </SmoothModal>
</template>

<script>
import { ref } from 'vue';

import SmoothModal from '@/components/SmoothModal';
import SplitInput from '@/components/Inputs/SplitInput.vue';

import { useStore } from '@/store';
import { twoFaAuthentificate } from '@/api/auth';
import { router } from '@/router';
import {
  ActionForm, booleanRule, codeRule, useValidation, yup,
} from '@/validation';
import { emitter } from '@/composables/useBus';
import { useModel } from '@/composables/useModel';

import { walletRemove } from './api';

export default {
  name: 'ModalToDisconnectWallet',
  components: {
    SplitInput,
    SmoothModal,
    ActionForm,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { value } = useModel(props, emit);
    const {
      initState,
      fieldsErrors,
      processError,
      clearState,
    } = useValidation();

    const fields = {
      rule1: false,
      rule2: false,
      rule3: false,
      totpCode: '',
    };
    const validationSchema = yup.object().shape({
      totpCode: codeRule,
      rule1: booleanRule,
      rule2: booleanRule,
      rule3: booleanRule,
    });

    const { requestFields } = initState(fields);

    const isRemoveProcessLoading = ref(false);
    const onPassedValidation = async () => {
      isRemoveProcessLoading.value = true;
      const { isSuccess, errorObject } = await twoFaAuthentificate({
        totpCode: requestFields.totpCode,
      });
      isRemoveProcessLoading.value = false;
      if (isSuccess) {
        isRemoveProcessLoading.value = true;
        const { isSuccess: isWalletRemoved } = await walletRemove();
        if (isWalletRemoved) {
          const store = useStore();
          store.setWalletData({});
        }
        emitter.emit('update-wallet-page', () => {
          console.log('/push');
          router.push('/wallet-setup');
        });
        isRemoveProcessLoading.value = false;
        closeModal();
      } else {
        processError(errorObject, 'wrongToken', 'totpCode');
      }
    };

    const closeModal = () => {
      emit('update:modelValue', false);
      clearState();
    };

    return {
      value,
      closeModal,
      onPassedValidation,

      validationSchema,
      requestFields,
      fieldsErrors,

      isRemoveProcessLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.wallet {
  padding-top: 30px;

  .rules {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0 20px;
    padding-bottom: 50px;
    border-bottom: 1px solid var(--color-black-01);

    :deep(.el-checkbox) {
      align-items: start;
      .el-checkbox__inner {
        position: relative;
        top: 4px;
      }
    }
  }
}

</style>
