<template>
  <div class="plan-info">
    <AppIcon :name="icon" class="plan-icon" size="48px" is-img-tag />
    <div>
      <AppText
        variant="div"
        size="17px"
        class="font-medium is-first-letter-capitalized"
        mt="16px"
        mb="8px"
      >
        {{ title }}
      </AppText>
      <AppText
        variant="div"
        opacity="0.4"
        style="max-width: 200px;"
      >
        {{ description }}
      </AppText>
    </div>
  </div>
</template>

<script>
import { makeFirstLetterUppercased } from '@/utils/functions';

export default {
  name: 'PlanInfo',
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  setup() {
    return { makeFirstLetterUppercased };
  },
};
</script>

<style scoped lang="scss">
.plan-info {
  display: flex;
  gap: 30px;
  width: 320px;
  height: 93px;

  .plan-icon {
    align-self: flex-start;
    margin-top: 8px;
  }
}
</style>
