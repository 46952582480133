import { useFetch } from '@/api';

export const walletRemove = async () => {
  const { axiosWrapper, successHandler, t } = useFetch();
  const { isSuccess } = await axiosWrapper({
    type: 'delete',
    url: '/settings/merchant/non-custodial/wallet',
  });

  if (isSuccess) {
    successHandler(t('merchantWallet.notification.walletRemoveSuccess'));
  }

  return { isSuccess };
};
