<template>
  <div class="plan-selector">
    <div class="header">
      <div class="title">
        <AppText
          size="18px"
          class="font-medium"
        >
          {{ t('merchantBilling.modalToChangePlan.select.title') }}
        </AppText>
        <DotSeparator color="rgba(0, 0, 0, 0.2)" margin="0" />
        <div>
          <AppText size="15px" :opacity="0.4" class="font-medium">
            {{ t('merchantBilling.modalToChangePlan.select.yourBalance') }}
          </AppText>&nbsp;
          <CurrencyFormatter
            variant="div"
            size="15px"
            :opacity="0.4"
            class="font-medium"
            is-raw
            :value="balance"
          />
        </div>
      </div>
      <AppText size="14px" :opacity="0.4" :line-height="1.5" variant="div">
        {{ t('merchantBilling.modalToChangePlan.select.description') }}
      </AppText>
    </div>

    <div class="body">
      <PlanItem
        v-for="plan in plansList"
        :key="plan.name"
        :plan="plan"
        @select-plan="onSelectPlan"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';
import DotSeparator from '@/components/DotSeparator.vue';

import PlanItem from './PlanItem.vue';

export default {
  name: 'PlanSelector',
  components: {
    CurrencyFormatter,
    PlanItem,
    DotSeparator,
  },
  props: {
    balance: {
      type: Number,
      default: 0,
    },
    accountTypes: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['select-plan'],
  setup(props, { emit }) {
    const plansList = computed(() => Object.keys(props.accountTypes)
      .filter((key) => key !== 'free')
      .reduce((obj, key) => {
        obj[key] = props.accountTypes[key];
        return obj;
      }, {}));

    const onSelectPlan = (plan) => {
      emit('select-plan', plan);
    };

    return {
      plansList,
      onSelectPlan,
    };
  },
};
</script>

<style lang="scss" scoped>
.plan-selector {
  display: flex;
  flex-direction: column;

  .header {
    padding-top: 40px;
    height: 136px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .title {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .body {
    border-top: 1px solid var(--color-black-007);
    padding-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
</style>
