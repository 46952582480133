<template>
  <div v-if="amount" class="plan-limits">
    <div>
      <AppText
        variant="div"
        class="font-medium"
        size="17px"
        mt="25px"
        mb="8px"
      >
        {{ isBlack
          ? `${t('merchantBilling.modalToChangePlan.select.unlimited')} ${t('common.payments')} `
          : `${calculatedPayments} ${t('common.payments')}`
        }}
      </AppText>
      <CurrencyFormatter
        class="font-medium"
        is-raw
        :value="amount"
      />
      <AppText
        mt="12px"
        ml="8px"
        pl="8px"
        color="var(--color-black-04)"
        style="border-left: 1px solid var(--color-D9D9D9);"
      >
        {{ t(`cycle.${cycle}`) }}
      </AppText>
    </div>
    <div class="icon-wrap">
      <AppIcon name="arrow-basic" class="rotate-90" size="24px" />
    </div>
  </div>

  <div v-else class="no-data">
    <div class="block" />
  </div>
</template>

<script>
import { computed } from 'vue';

import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';

export default {
  name: 'PlanLimits',
  components: {
    CurrencyFormatter,
  },
  props: {
    amount: {
      type: Number,
      default: null,
    },
    total: {
      type: [Number, String],
      default: 0,
    },
    cycle: {
      type: String,
      default: '',
    },
    isBlack: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const calculatedPayments = computed(() => (props.cycle === 'YEARLY' && (typeof props.total === 'number') ? props.total * 10 : props.total));

    return { calculatedPayments };
  },
};
</script>

<style scoped lang="scss">
.plan-limits {
  cursor: pointer;
  width: 324px;
  height: 93px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  background: var(--color-black-005);
  border-radius: 8px;
  @include transition-base('background');

  &:hover {
    background: #EDEDED;

    :deep(.icon path) {
      fill: var(--color-primary);
    }
  }

  :deep(.icon) {
    path {
      fill: var(--color-black-01);
      @include transition-base('fill');
    }
  }

  .icon-wrap {
    display: flex;
    @include transition-base('display');
    align-items: center;
  }
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 324px;
  height: 93px;

  .block {
    background: var(--color-black-01);
    width: 20px;
    height: 4px;
    border-radius: 2px;
  }
}
</style>
