<template>
  <div class="selected-plan">
    <div class="header">
      <div class="back-btn" @click="goBack">
        <AppIcon
          name="arrow-basic"
          class="rotate-270"
          :opacity="0.4"
          size="18px"
          is-img-tag
        />
      </div>
    </div>

    <div class="title">
      <AppText size="24px" class="font-medium d-flex">
        <i18n-t :keypath="isUpgrade ? 'merchantBilling.modalToChangePlan.preview.upgrade' : 'merchantBilling.modalToChangePlan.preview.downgrade'">
          <template #icon>
            <AppIcon :name="selectedPlan.plan.icon" size="28px" is-img-tag style="margin: 0 15px;" />
          </template>
          <template #plan>
            <AppText
              size="24px"
              class="font-medium is-first-letter-capitalized"
            >
              {{ selectedPlan.plan.name }}
            </AppText>
          </template>
        </i18n-t>
      </AppText>
    </div>

    <div class="info">
      <div class="item">
        <AppText>
          <i18n-t keypath="merchantBilling.modalToChangePlan.preview.paymentsPer">
            <template #period>
              {{ period }}
            </template>
          </i18n-t>
        </AppText>
        <AppText
          size="20px"
          class="font-medium"
          variant="div"
          mt="12px"
        >
          {{ limits }}
        </AppText>
      </div>

      <div class="item">
        <AppText class="is-first-letter-capitalized">
          <template v-if="selectedPlan.cycle === 'MONTHLY'">
            {{ t('merchantBilling.modalToChangePlan.preview.monthlyCost') }}
          </template>
          <template v-else>
            {{ t('merchantBilling.modalToChangePlan.preview.yearlyCost') }}
          </template>
        </AppText>
        <CurrencyFormatter
          is-raw
          :value="changeCost"
          size="20px"
          class="font-medium"
          variant="div"
          mt="12px"
        />
      </div>

      <div class="item">
        <AppText>
          {{ t('merchantBilling.modalToChangePlan.preview.dueDate') }}
        </AppText>
        <AppText
          size="20px"
          class="font-medium"
          variant="div"
          mt="12px"
        >
          <DateFormatter
            size="20px"
            class="font-medium"
            variant="div"
            :date="newDueDate"
          />
        </AppText>
      </div>
    </div>

    <AppText
      variant="div"
      :line-height="1.5"
      style="width: 440px; text-align: center;"
      mt="40px"
      mb="40px"
    >
      <i18n-t keypath="merchantBilling.modalToChangePlan.preview.description" tag="div">
        <span class="font-medium">{{ t('merchantBilling.modalToChangePlan.preview.descriptionSubtext') }}</span>
      </i18n-t>
    </AppText>

    <FButton :loading="isLoading" no-margin @click="onSelectPlan">
      <i18n-t v-if="isUpgrade" keypath="merchantBilling.modalToChangePlan.preview.buttonUpgrade">
        <template #money>
          <span>
            &nbsp;<CurrencyFormatter class="font-medium" is-raw :value="changeCost" />
          </span>
        </template>
      </i18n-t>
      <i18n-t v-else keypath="merchantBilling.modalToChangePlan.preview.buttonDowngrade">
        <template #money>
          <span>
            &nbsp;<CurrencyFormatter class="font-medium" is-raw :value="changeCost" />
          </span>
        </template>
      </i18n-t>
    </FButton>
  </div>
</template>

<script>
import { computed } from 'vue';
import dayjs from 'dayjs';

import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';
import DateFormatter from '@/components/Helpers/DateFormatter.vue';

import { i18n } from '@/plugins/localization';

export default {
  name: 'SelectedPlan',
  components: {
    CurrencyFormatter,
    DateFormatter,
  },
  props: {
    selectedPlan: {
      type: Object,
      required: true,
    },
    isUpgrade: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['go-back', 'confirm'],
  setup(props, { emit }) {
    const goBack = () => { emit('go-back'); };
    const onSelectPlan = () => { emit('confirm'); };

    const { t } = i18n.global;

    const actionName = computed(() => (props.isUpgrade ? t('merchantBilling.modalToChangePlan.preview.upgrade') : t('merchantBilling.modalToChangePlan.preview.downgrade')));
    const changeCost = computed(() => (props.selectedPlan.cycle === 'MONTHLY' ? props.selectedPlan.plan.perMonth : props.selectedPlan.plan.perYear));
    const limits = computed(() => {
      if (props.selectedPlan?.plan?.name === 'black') {
        return t('merchantBilling.modalToChangePlan.preview.unlimited');
      }
      return props.selectedPlan.cycle === 'YEARLY' && (typeof props.selectedPlan.plan.limits.payments === 'number')
        ? props.selectedPlan.plan.limits.payments * 10
        : props.selectedPlan.plan.limits.payments;
    });
    const newDueDate = computed(() => (props.selectedPlan.cycle === 'MONTHLY' ? dayjs().add(30, 'day').format() : dayjs().add(365, 'day').format()));
    const period = computed(() => (props.selectedPlan.cycle === 'MONTHLY' ? t('period.month', 1) : t('period.year', 1)));

    return {
      goBack,
      onSelectPlan,
      actionName,
      changeCost,
      limits,
      newDueDate,
      period,
    };
  },
};
</script>

<style scoped lang="scss">
.selected-plan {
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    margin-top: 30px;
    align-self: flex-start;

    .back-btn {
      cursor: pointer;
      width: 40px;
      height: 40px;
      background: var(--color-F2F2F2);
      border-radius: 50%;
      @include flex-center;
    }
  }

  .title {
    margin-top: 5px;
    display: flex;
    align-items: center;
  }

  .info {
    width: 550px;
    margin-top: 40px;
    padding: 40px 50px;
    background: var(--color-black-003);
    border-radius: 12px;
    display: flex;

    .item {
      width: calc(100%/3);
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
