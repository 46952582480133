const elem = document.querySelector('meta[name=viewport]');
const addViewportMeta = () => {
  setTimeout(() => {
    elem.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0';
    return true;
  }, 0);
};

const removeViewportMeta = () => {
  setTimeout(() => {
    elem.content = '';
    return true;
  }, 0);
};

export {
  addViewportMeta,
  removeViewportMeta,
};
