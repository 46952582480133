<template>
  <div class="plan">
    <PlanInfo
      :icon="plan.icon"
      :title="t(`plan.${plan.name}.title`)"
      :description="t(`plan.${plan.name}.description`)"
    />

    <PlanLimits
      :total="plan.limits.payments"
      :amount="plan.perMonth"
      :cycle="cycles.Monthly"
      :is-black="plan.name === 'black'"
      @click="onClick(cycles.Monthly)"
    />

    <PlanLimits
      :total="plan.limits.payments"
      :amount="plan.perYear"
      :cycle="cycles.Yearly"
      :is-black="plan.name === 'black'"
      @click="onClick(cycles.Yearly)"
    />
  </div>
</template>

<script>
import PlanInfo from './PlanInfo.vue';
import PlanLimits from './PlanLimits.vue';

export default {
  name: 'PlanItem',
  components: {
    PlanInfo,
    PlanLimits,
  },
  props: {
    plan: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['select-plan'],
  setup(props, { emit }) {
    const cycles = Object.freeze({
      Monthly: 'MONTHLY',
      Yearly: 'YEARLY',
    });

    const onClick = (cycle) => {
      if (cycle === cycles.Monthly && !props.plan.perMonth) { return; }

      emit('select-plan', { plan: props.plan, cycle });
    };

    return { cycles, onClick };
  },
};
</script>

<style scoped lang="scss">
.plan {
  display: flex;
  gap: 20px;
}
</style>
