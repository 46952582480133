import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';

import { currencyList } from '@/common/data';
import { useStore } from '@/store';

const store = useStore();

const { currencySign, currencyValue } = storeToRefs(store);

const differentCurrency = ref(null);

const currencyObj = ref({
  sign: currencySign,
  value: currencyValue,
});

export const initCurrency = (currency) => {
  const { value, sign } = currencyList.find((item) => item.short === currency);
  differentCurrency.value = {
    sign,
    value,
  };
  return differentCurrency.value;
};

export const currency = computed(() => (differentCurrency.value ? differentCurrency.value : currencyObj.value));
