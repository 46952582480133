import { storeToRefs } from 'pinia';

import { useStickiesStore } from '@/store';

export const useStickies = () => {
  const store = useStickiesStore();
  const {
    active,
    history,
    isHistoryLoaded,
    isStickiesLoaded,
    historyPagination,
    stickiesStartingYear,
    stickiesSelectedYear,
  } = storeToRefs(store);

  return {
    store,
    active,
    history,
    isHistoryLoaded,
    isStickiesLoaded,
    stickiesStartingYear,
    historyPagination,
    stickiesSelectedYear,
  };
};
