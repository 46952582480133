import FormData from 'form-data';

import { useFetch } from '@/api';
import { useNotifications } from '@/composables/useNotifications';
import { getLocalizedErrorMessage } from '@/composables/useFetch/helpers';

export const createTicket = async ({ tag, message, files }) => {
  const form = new FormData();
  files.forEach((file) => {
    form.append('files', file.raw, file.name);
  });
  form.append('tag', tag);
  form.append('message', message);

  try {
    const { axios, successHandler, t } = useFetch();
    const result = await axios.postForm(
      '/merchants/support/create-ticket',
      form,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );

    const isSuccess = result.data.success;
    if (isSuccess) {
      successHandler(t('component.contactSupport.ticketIsCreated'));
    }

    return {
      result, data: result.data, isSuccess,
    };
  } catch (error) {
    const { addNotification } = useNotifications();
    const errorMessage = getLocalizedErrorMessage(error.response?.data?.message);
    addNotification({ text: errorMessage, config: { color: 'error' } });

    return { isSuccess: false };
  }
};
