import { onBeforeRouteLeave } from 'vue-router';
import { onBeforeUnmount, ref, watch } from 'vue';

export const onTabChange = () => {
  // eslint-disable-next-line
  const res = window.confirm('Changes you made may not be saved. Continue?');

  if (!res) {
    return false;
  }

  return true;
};

export const usePageLeaving = ({
  watchedRef,
  condition,
  initialValue,
  callback,
}) => {
  const onTabClose = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const isPopupListenerAdded = ref(false);
  const removeListener = () => {
    window.removeEventListener('beforeunload', onTabClose);
    isPopupListenerAdded.value = false;
  };

  onBeforeUnmount(() => {
    removeListener();
  });

  watch(watchedRef, () => {
    if (condition(watchedRef) && !isPopupListenerAdded.value) {
      window.addEventListener('beforeunload', onTabClose);
      isPopupListenerAdded.value = true;
    }

    if (watchedRef.value === initialValue) {
      removeListener();
    }
  }, { immediate: true });

  onBeforeRouteLeave((route) => {
    if (condition(watchedRef, route.name)) {
      // eslint-disable-next-line
      const res = window.confirm('Changes you made may not be saved. Continue?');

      if (!res) {
        return false;
      }
    }

    if (callback) {
      callback();
    }

    return true;
  });
};
