<template>
  <SmoothModal
    v-model="value"
    width="660px"
    :has-header-divider="false"
    is-description-visible
    @update:model-value="closeModal"
  >
    <template #title>
      {{ t('component.contactSupport.title') }}
    </template>
    <template #description>
      <AppText
        variant="div"
        size="13px"
        :line-height="1.35"
      >
        <i18n-t keypath="component.contactSupport.description" tag="div">
          <template #link>
            <ExternalLink has-primary-color :has-prepend="false" :has-append="false" :href="docsLink">
              {{ t('component.contactSupport.descriptionLink') }}
            </ExternalLink>
          </template>
        </i18n-t>
      </AppText>
    </template>

    <ActionForm
      :request-fields="requestFields"
      :validation-schema="validationSchema"
      :response-errors="fieldsErrors"
      @validate="onPassedValidation"
    >
      <template #default="{ errors, action }">
        <FRadioButton
          v-model="requestFields.tag"
          :options="typeOptions"
          is-secondary
          class="radio-group"
        >
          <template #default="item">
            {{ t(`component.contactSupport.option.${item.value}`) }}
          </template>
        </FRadioButton>
        <div style="margin-bottom: 20px;">
          <FInput
            v-model="requestFields.message"
            :readonly="isFetchProcessing"
            :validation-error="errors.message"
            type="textarea"
            :textarea-rows="7"
            :is-inherit-attrs="false"
            has-force-error
            :has-error="requestFields.message?.length > 500"
            maxlength="500"
            show-word-limit
            :placeholder="t('component.contactSupport.memo')"
          />
        </div>

        <FUpload
          v-model="requestFields.files"
          drag
          multiple
          :limit="5"
          :disabled="isFetchProcessing"
        >
          <div class="drag-area">
            <AppIcon name="link" size="18px" is-img-tag style="margin-right: 8px;" />
            <AppText>
              {{ t('component.contactSupport.attachFilesNote') }}
            </AppText>
          </div>
        </FUpload>

        <div class="d-flex justify-content-between" style="clear: left;">
          <FButton :loading="isFetchProcessing" @click="action">
            {{ t('component.contactSupport.sendButton') }}
          </FButton>
        </div>
      </template>
    </ActionForm>
  </SmoothModal>
</template>

<script>
import { ref } from 'vue';

import SmoothModal from '@/components/SmoothModal';
import ExternalLink from '@/components/ExternalLink.vue';

import { ActionForm, useValidation, yup } from '@/validation';
import { memoRule } from '@/validation/rules';
import { useModel } from '@/composables/useModel';

import { createTicket } from './api';

const typeOptions = [
  { value: 'QUESTION' },
  { value: 'FEEDBACK' },
  { value: 'BUG' },
];

export default {
  name: 'ModalToContact',
  components: {
    SmoothModal,
    ActionForm,
    ExternalLink,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { value } = useModel(props, emit);
    const {
      initState,
      fieldsErrors,
      clearState,
    } = useValidation();

    const fields = {
      tag: typeOptions[0].value,
      message: '',
      files: [],
    };
    const validationSchema = yup.object().shape({
      message: memoRule({ min: 10, max: 500 }),
    });

    const { requestFields } = initState(fields);

    const isFetchProcessing = ref(false);
    const onPassedValidation = async () => {
      isFetchProcessing.value = true;
      const { isSuccess } = await createTicket(requestFields);
      if (isSuccess) {
        closeModal();
      }
      isFetchProcessing.value = false;
    };

    const closeModal = () => {
      emit('update:modelValue', false);
      clearState();
    };

    const docsLink = `https://${process.env.VUE_APP_DOCS_URL}`;

    return {
      value,
      closeModal,
      onPassedValidation,

      validationSchema,
      requestFields,
      fieldsErrors,

      typeOptions,
      isFetchProcessing,
      docsLink,
    };
  },
};
</script>

<style lang="scss" scoped>
.radio-group {
  margin-bottom: 20px;
}

.drag-area {
  padding: 14px 10px;
  @include flex-center;
}
</style>
